.image-with-caption__centering-wrapper {
  text-align: center;
}

.image-with-caption {
  margin-top: $spacing-unit;
}

.image-with-caption__image-block {
  img {
    @include media-query($mobile-breakpoint) {
      padding: 0 8%;
    }
  }
}

.image-with-caption__caption-block {
  @extend .caption;
  padding-top: 6px;
  text-align: center;
  max-width: 75%;
}

.image-dictionary__centering-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
}

@include media-query($mobile-breakpoint) {
  .image-dictionary__centering-wrapper {
    justify-content: space-evenly;
  }
}

.image-dictionary__definition-block {
  padding: 0 3px;
}

.image-dictionary__image {
}

.image-dictionary__hover-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;
}

.image-dictionary__definition-block:hover {
  .image-dictionary__hover-image {
    visibility: visible;
  }
}

.image-dictionary__text {
  @extend .caption;
  text-align: center;
  max-width: 200px;
}

// Spoiler with hover-reveal text
.image-with-caption__caption-block,
.image-dictionary__text {
  &.-reveal {
    background-color: $color-green-C;
    color: $color-green-C;
    transition: background-color .75s, color 1s;
    &:active, &:focus, &:hover {
      background-color: inherit;
      color: inherit;
    }
  }
}

// Grouping Modifiers:

// for syllabary (5 across)
.image-dictionary.group-by-5 {
  .image-dictionary__centering-wrapper {
    max-width: 460px;
    margin: auto;
  }
  :nth-child(5n+6) {
    @include media-query($tablet-breakpoint) {
      clear: both;
    }
  }
  .image-dictionary__text {
    display: block;
    height: 18px;
    background-color: $color-clear;
  }
}


// for step-by-step drawings (uneven sized images) - fixed height for float

.image-dictionary.group-by-height {
  .image-dictionary__image {
    img {
      height: 150px;
      display: block;
      margin: auto;
    }
  }
}

// for glyph examples - restrain text under image.
.image-dictionary.group-by-height-and-width {
  @extend .image-dictionary, .group-by-height;
  .image-dictionary__centering-wrapper {
    max-width: 625px;
    margin: auto;
  }
  .image-dictionary__definition-block {
    height: 220px;
  }
  .image-dictionary__text {
    max-width: 120px;
  }
}

.image-gallery {
  display: flex;
  flex-flow: row wrap;
  .image-gallery__image {
    max-width: 32%;
  }
  .image-gallery__hover-text {
    .-hover-text {
      opacity: 0;
    }
    &:hover .-hover-text {
      opacity: 1;
      transition: opacity 1s;
    }
  }
}
