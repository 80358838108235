blockquote.grammar {
  border-left: none;
  font-style: inherit;
  background-color: $color-grey-E;
  padding: 1rem;
  ul {
    list-style-type: none;
  }
  table {
    text-align: right;
    line-height: 13px;
    th {
      font-weight: 300;
    }
    td {
      width: 40px;
    }
  }
}
