/**
 * Wrappers
 */
.wrapper__page, .wrapper__content, .wrapper__footer {
	margin-right: auto;
	margin-left: auto;
	padding-right: calc($spacing-unit / 4);
	padding-left: calc($spacing-unit / 4);
	@extend %clearfix;

	@include media-query($tablet-breakpoint) {
		max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
	}
}

.wrapper__content {
	background-color: $color-background-content;
	padding-top: $spacing-unit;
	padding-bottom: $spacing-unit * 2;
}

.wrapper__center-image {
	width: 100%;
	background-color: $color-white-D;
	@extend .caption;
	img {
		margin-left: auto;
		margin-right: auto;
		display: block
	}
}

.wrapper__footer-col {
	font-size: 15px;
	color: $color-grey-8;
	margin-left: calc(($spacing-unit / 2) * -1);
	@extend %clearfix;
}

/**
 * Site header
 */
#site-header {
	border-top: 5px solid $color-grey-4;
	border-bottom: 1px solid $color-grey-E;
	min-height: 56px;
	background-color: $color-background-content;
	padding-left: calc($spacing-unit / 2);
	@include media-query-smaller-than($tablet-breakpoint) {
		@include inverse;
		border: none;
	  padding-left: 0;
	}
}

#site-header__nav {
	ul {
		margin: 0;
		@include media-query($tablet-breakpoint) {
			margin: 1rem;
		}
	}
	li {
	  text-transform: uppercase;
	  clear: both;
	  list-style: none;
	  text-align: center;
	  border-bottom: 1px solid $color-gris-E;
  	@include media-query($tablet-breakpoint) {
  		border-bottom: none;
  		font-size: .75rem;
  		clear: none;
      float: right;
	    padding: 4px 5px 0 5px;
    }
	}
}

h1.site-header__title {
	font-size: 1.25rem;
	line-height: 56px;
	letter-spacing: -1px;
	margin-bottom: 0;
	color: $color-action-complete;
	text-align: center;
	&:hover {
		text-decoration: none;
	}
	&:visited {
		color: $color-action-complete;
	}
	@include media-query($tablet-breakpoint) {
    float: left;
		text-align: left;
   }
}

/**
 * Main content (posts and pages)
 */

.content__title {
	letter-spacing: -1px;
	line-height: 1;
	color: $color-accent;
}

.content__sub-title {
	font-size: $small-font-size;
	color: $color-grey-8;
}

/**
 * Site footers
 */

.footer__tag-list {
	@include inverse;
	overflow: hidden;
	width: 100%;
	margin-bottom: 0;
	span {
		float: left;
		padding: .25rem;
		color: $color-grey-E;
	}
	ul {
		float: left;
		margin: 0;
	}
	ul li {
		float: left;
		margin: 0;
		padding: 0;
		list-style-type: none;
		background-color: $color-grey-8;
		a {
			display: block;
			margin: 0 1px;
			padding: .25rem;
			&:hover {
				background-color: $color-grey-4;
			}
		}
	}
}

.site-footer {
	@include inverse;
	border-top: 1px solid $color-grey-E;
	padding: $spacing-unit 0;
}

.contact-list,
.social-media-list {
	list-style: none;
	margin-left: 0;
}


.footer-col {
	float: left;
	margin-bottom: calc($spacing-unit / 2);
	padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
	width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
	width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
	width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query-smaller-than($tablet-breakpoint) {
	.footer-col-1,
	.footer-col-2 {
		width:         calc(50% - (#{$spacing-unit} / 2));
	}

	.footer-col-3 {
		width:         calc(100% - (#{$spacing-unit} / 2));
	}
}

@include media-query-smaller-than($mobile-breakpoint) {
	.footer-col {
		float: none;
		width:         calc(100% - (#{$spacing-unit} / 2));
	}
}
