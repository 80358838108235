// Colors (0 is lightest)

$color-white-D: #F9F9F9;
$color-white-C: #F4F4F4;

$color-grey-E: #E8E8E8;
$color-grey-B: #BBBBBB;
$color-grey-A: #AAAAAA;
$color-grey-9: #999999;
$color-grey-8: #828282;
$color-grey-5: #555555;
$color-grey-4: #424242;
$color-grey-1: #111111;
$color-grey-0: #000000;

$color-gris-E: #F1F1E9;
$color-gris-A: #AF9B87;
$color-gris-C: #CCCCB5;
$color-gris-9: #99947B;
$color-gris-8: #8A8A55;
$color-gris-6: #62623C;
$color-gris-4: #4A4A2E;

$color-brown-F: #FFFCE6;
$color-brown-D: #FFEE80;
$color-brown-B: #FFC992;
$color-brown-C: #E9D75E;
$color-brown-A: #AF7941;
$color-brown-9: #D28A42;
$color-brown-7: #72512F;

$color-green-E: #B0FFD9;
$color-green-C: #BCED68;
$color-green-A: #9BCE42;
$color-green-9: #86C352;
$color-green-7: #74AF0E;
$color-green-5: #60910C;
$color-green-4: #517217;
$color-green-2: #3B5411;

$color-blue-D: #AEFDFF;
$color-blue-B: #91C8BF;
$color-blue-7: #009999;
$color-blue-6: #008080;

$color-red-F: #F5E8E3;
$color-red-9: #FFBCBC;
$color-red-8: #D17F5C;
$color-red-3: #A61717;
$color-red-1: #990000;

// From syntax highlighter (need adjustment)
$color-red-AA: #AA0000;
$color-red-DD: #DD1144;
$color-red-E3: #E3D2D2;

$color-blue-8: #0086B3;

$color-clear: rgba(0,0,0,0);

// Layout

$color-background-content: $color-white-D;
$color-background-gutter: $color-gris-E;

// Text

$color-text: $color-grey-1;

$color-action: $color-green-7;
$color-action-complete: $color-green-5;

$color-action-inverse: $color-green-A;
$color-action-inverse-complete: $color-green-7;

$color-accent: $color-brown-7;

