@charset "utf-8";

@import "color";

// Text
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   1rem;
$small-font-size:  $base-font-size * 0.975;
$base-line-height: 1.5;

$monospace-font-family: Consolas,'Lucida Console','DejaVu Sans Mono', monospace;

// Layout
$content-width:    800px;

$spacing-unit:     2rem;
$spacing-unit-bottom: calc($spacing-unit / 2);

// Design mobile first, override with breakpoints:
$mobile-breakpoint:        600px;
$tablet-breakpoint:        800px;



// Use media queries like this:
// @include media-query($mobile-breakpoint) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }
@mixin media-query($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}

@mixin media-query-smaller-than($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin inverse {
  color: $color-gris-A;
  background-color: $color-grey-4;
  a {
    color: $color-action-inverse;
    &:hover {
      color: lighten($color-action-inverse, 25%);
    }
    &:visited {
      color: $color-action-inverse-complete;
      &:hover {
        color: lighten($color-action-inverse-complete, 15%);
      }
    }
  }
}

.small-text {
  font-size: $small-font-size;
  color: $color-grey-8;
  letter-spacing: -.5px;
  line-height: 1.125rem;
}

.clear {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "artwork",
        "base",
        "layout",
        "syntax_highlighting",
        "image_index",
        "images",
        "sub_nav",
        "custom"
;


// for roadmap table (temporary):

table {
  font-size: $small-font-size;
  tr.done {
    color: $color-grey-E;
    &:hover {
      color: $color-grey-A;
    }
    a {
      color: $color-grey-E;
      &:hover { color: $color-grey-A}
    }
  }
  tr.pending {
    color: $color-grey-B;
    &:hover {
      color: $color-grey-A;
    }
    a {
      color: $color-grey-B;
      &:hover { color: $color-grey-A}
    }
  }
}
