/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 300;
  color: $color-text;
  background-color: $color-background-gutter;
  -webkit-text-size-adjust: 100%;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit-bottom;
}

/**
 * Images
 */
img {
  max-width: 100%;
}

.data-text {
   font-size: .85rem;
   font-family: $monospace-font-family;
   color: $color-gris-9;
}

.caption {
  @extent .data-text;
  margin: 0 auto 1rem auto;
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}


/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
     margin-bottom: 0;
  }
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

h1 {
  font-size: 1.75rem;
  @include media-query($tablet-breakpoint) {
    font-size: 2rem;
  }
}


h2 {
  font-size: 1.65rem;
  @include media-query($tablet-breakpoint) {
    font-size: 1.75rem;
  }
}

h3 {
  font-size: 1.25rem;

  @include media-query($tablet-breakpoint) {
    font-size: 1.33rem;
  }
}

h4 {
  font-size: 1.125rem;
  color: $color-brown-7;

  @include media-query($tablet-breakpoint) {
    font-size: 1.25rem;
  }
}

h5 {
  font-size: .9rem;
  font-weight: 400;

  @include media-query($tablet-breakpoint) {
    font-size: .95rem;
  }
}

/**
 * Emphasis
 */
i, u, em {
  color: $color-gris-4;
}

strong {
  font-weight: 500;
}


/**
 * Links
 */
a {
  color: $color-action;
  &:visited {
    color: $color-action-complete;
  }

  &:hover {
    color: darken($color-action, 15%);
    text-decoration: underline;
  }
}

/**
 * Blockquotes
 */
blockquote {
  @extend .small-text;
  border-left: 2px solid $color-grey-E;
  padding-left: calc($spacing-unit / 2);
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

/**
 * Clearfix
 */
%clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/**
 * Icons
 */
.icon {

  > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
      fill: $color-grey-8;
    }
  }
}

dt {
  font-weight: 700;
}

dd {
  padding: 0 0 .5rem 1rem;
}
