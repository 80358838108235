
.image-index__article {
  margin-bottom: calc($spacing-unit / 4);
  border-bottom: 1px solid $color-gris-C;
}

.image-index__image {
  width: 100%;
  img {
    margin: 10px auto 10px auto;
    max-width: 150px;
    max-height: 150px;
    display: block;
  }
}

.image-index__description {}

.image-index__description-title {
  padding-top: 5px;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.image-index__description-date {
  @extend .small-text;
  color: $color-grey-5;
  padding-bottom: .25rem;
}

.image-index__description-text {
  @extend .small-text;
}


.image-index__description-item {
  color: $color-gris-A;
  margin-left: 5 * $spacing-unit;
}


@include media-query($mobile-breakpoint) {
  .image-index__article {
    img {
      float: left;
      margin: 10px 15px 10px 0;
    }
  }
}
